import {
  Anchor,
  Box,
  BoxProps,
  Container,
  Flex,
  Title,
  rem,
} from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import Link from "next/link";

interface Props extends BoxProps {
  title?: string;
  children: React.ReactNode;
  bg?: string;
  titleUrl?: string;
  titleUrlLabel?: React.ReactNode;
  titleClass?: string;
}

export default function SectionItem({
  title,
  children,
  titleUrl,
  titleUrlLabel,
  titleClass,
  ...rest
}: Props): JSX.Element {
  return (
    <Box py={rem(40)} {...rest}>
      <Container size="xl">
        <Flex justify="space-between" align="center">
          <Title
            order={2}
            size={rem(28)}
            className={`mb-2.5 !text-dark-9 text-2xl md:text-2.75xl ${titleClass}`}
          >
            {title}
          </Title>
          {titleUrlLabel && titleUrl && (
            <Anchor
              component={Link}
              href={titleUrl}
              className="flex hover:underline text-custom-link underline-offset-4 decoration-1"
            >
              {titleUrlLabel} <IconChevronRight strokeWidth={1} />
            </Anchor>
          )}
        </Flex>
        {children}
      </Container>
    </Box>
  );
}
